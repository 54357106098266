import React, { useState } from 'react'
import { DragAndDrop, Flex } from '@revolut/ui-kit'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import { QuestionItem } from './QuestionItem'

type Props = {
  addedQuestions: EngagementQuestionInterface[]
  onDeleteQuestion: (id: number) => void
  onChangeQuestionsOrder: (id: number, overId: number | null) => void
  onDragStart?: () => void
}
export const DraggableQuestions = ({
  addedQuestions,
  onDeleteQuestion,
  onChangeQuestionsOrder,
  onDragStart,
}: Props) => {
  const [activeId, setActiveId] = useState<string | number | null>(null)
  const activeQuestion =
    activeId && addedQuestions.find(({ id }) => String(id) === activeId)

  return (
    <Flex flexDirection="column" gap="s-16" style={{ zIndex: 0 }}>
      <DragAndDrop.Provider
        onDragStart={event => {
          onDragStart?.()
          setActiveId(event.active.id)
        }}
        onDragEnd={({ active, over }) => {
          onChangeQuestionsOrder(Number(active.id), over?.id ? Number(over.id) : null)
        }}
      >
        <DragAndDrop.Sortable
          id="sortable"
          items={addedQuestions.map(({ id }) => String(id))}
        >
          {sortable => {
            const question = addedQuestions.find(({ id }) => String(id) === sortable.id)
            if (!question) {
              return null
            }
            return (
              <QuestionItem
                question={question}
                sortable={sortable}
                onDelete={() => onDeleteQuestion(question.id)}
              />
            )
          }}
        </DragAndDrop.Sortable>
        <DragAndDrop.DragOverlay>
          {activeQuestion && <QuestionItem question={activeQuestion} />}
        </DragAndDrop.DragOverlay>
      </DragAndDrop.Provider>
    </Flex>
  )
}
