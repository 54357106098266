import React from 'react'
import { HStack, Tag, Text, Token } from '@revolut/ui-kit'
import capitalize from 'lodash/capitalize'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { WorkScheduleListItemInterface } from '@src/interfaces/workSchedule'
import { selectorKeys } from '../api'

export const timeOffWorkScheduleNameColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Name',
    insert: ({ data }) => (
      <HStack space="s-8" align="center">
        <Text>{data.name || '-'}</Text>
        {data.is_default_schedule ? <Tag variant="status">default</Tag> : null}
      </HStack>
    ),
  }

export const timeOffWorkScheduleMembersColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.text,
    idPoint: 'members',
    dataPoint: 'members',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Members',
  }

export const timeOffWorkScheduleDaysWorkedColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Days worked',
    insert: ({ data }) => {
      const workingDays = data.working_days
        .map(daySettings => capitalize(daySettings.day))
        .join(', ')
      return <Text>{workingDays}</Text>
    },
  }

export const timeOffWorkScheduleHoursWorkedColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Hours worked',
    insert: ({ data }) => {
      if (!data.working_days?.length) {
        return '-'
      }
      const formatTimeInterval = (startTime: string | null, endTime: string | null) => {
        if (!startTime || !endTime) {
          return '-'
        }
        const startTimeHhMm = startTime.split(':').slice(0, 2).join(':')
        const endTimeHhMm = endTime.split(':').slice(0, 2).join(':')
        return `${startTimeHhMm} to ${endTimeHhMm}`
      }
      const timeIntervalsFormatted = data.working_days.map(daySettings =>
        formatTimeInterval(daySettings.work_start_time, daySettings.work_end_time),
      )
      const sameIntervals = !timeIntervalsFormatted.filter(
        interval => interval !== timeIntervalsFormatted[0],
      ).length

      if (sameIntervals) {
        return timeIntervalsFormatted[0]
      }
      return 'Custom'
    },
  }

export const timeOffWorkScheduleStatusColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    insert: ({ data }) => {
      let color = Token.color.foreground

      if (data.status.id === 'draft') {
        color = Token.color.greyTone50
      } else if (data.status.id === 'active') {
        color = Token.color.accent
      }
      return <Text color={color}>{data.status.name}</Text>
    },
  }
