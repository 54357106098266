import React, { ElementType } from 'react'
import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import HomeLandingPage from '@src/pages/Home/HomeLandingPage'
import { peopleSubtabs } from '@src/pages/People/peopleSubtabs'
import { recruitmentSubtabs } from '@src/pages/Recruitment/recruitmentSubtabs'
import { organisationSubtabs } from '@src/pages/Organisation/organisationSubtabs'
import HubLandingPage from '@src/pages/Hub/HubLandingPage'
import { MainTableContainer } from '@src/pages/MainSections/MainTableContainer'
import { TabBarWithPinningTabInterface } from '@src/interfaces/tabPinning'
import { PINNABLE_APPLICATION_TABS } from '@src/constants/hub'
import { IconName } from '@revolut/ui-kit'
import { performanceSubtabs } from '@src/pages/Performance/performanceSubtabs'
import { MainPage } from '@src/pages/MainSections/MainPage'

export interface MainSectionInterface {
  name: 'Home' | 'People' | 'Recruitment' | 'Organisation' | 'Performance' | 'Features'
  icon: IconName
  url?: string
  path?: string | string[]
  selectedPaths?: string[]
  canView: boolean | PermissionTypes[]
  component: ElementType
}

const getMainTableTabPermissions = (tab: TabBarWithPinningTabInterface) => {
  const subtabPermissions = tab.subtabs?.reduce(
    (allPermissions, subtab) => [...allPermissions, subtab?.permission],
    [] as (PermissionTypes | undefined)[],
  )
  return [tab.permission, ...(subtabPermissions || [])].filter(Boolean)
}

const createMainTableTabConfig = (
  tabs: TabBarWithPinningTabInterface[],
  category: string,
) => {
  const tabsWithPermissions = tabs.map(tab => {
    const permissions = getMainTableTabPermissions(tab)

    return {
      ...tab,
      permissions: permissions.length ? permissions : undefined,
      category,
    }
  })

  return {
    tabs: tabsWithPermissions,
    allTabsPermissions: tabsWithPermissions.reduce<PermissionTypes[]>(
      (prev, curr) => [...prev, ...(curr.permissions || [])],
      [],
    ),
  }
}

export const createMainSectionsConfig = (
  isCommercial = false,
): MainSectionInterface[] => {
  const people = createMainTableTabConfig(peopleSubtabs, 'People')
  const recruitment = createMainTableTabConfig(recruitmentSubtabs, 'Recruitment')
  const organisation = createMainTableTabConfig(organisationSubtabs, 'Organisation')
  const performance = createMainTableTabConfig(performanceSubtabs, 'Performance')

  const appTabs = PINNABLE_APPLICATION_TABS.map(tab => ({
    ...tab,
    id: tab.homeSectionId,
    title: tab.homeSectionTitle || tab.title,
    permissions: tab.canView,
  }))

  const homeSectionName = 'Home'
  const peopleSectionName = 'People'
  const recruitmentSectionName = 'Recruitment'
  const organisationSectionName = 'Organisation'
  const performanceSectionName = 'Performance'

  return [
    {
      name: homeSectionName,
      icon: isCommercial ? 'Utilities' : 'LogoRevolut',
      url: ROUTES.MAIN,
      path: [ROUTES.MAIN, ROUTES.PINNED],
      selectedPaths: [ROUTES.MAIN, ROUTES.PINNED],
      component: () => (
        <HomeLandingPage
          sectionName={homeSectionName}
          tabs={[
            ...people.tabs,
            ...recruitment.tabs,
            ...organisation.tabs,
            ...performance.tabs,
            ...appTabs,
          ]}
        />
      ),
      canView: true,
    },
    {
      name: peopleSectionName,
      icon: 'People',
      url: ROUTES.PEOPLE.EMPLOYEES,
      path: [ROUTES.PEOPLE.ANY],
      selectedPaths: [
        ROUTES.PEOPLE.ANY,
        ROUTES.FORMS.EMPLOYEE.ANY,
        ROUTES.FORMS.SENIORITY.ANY,
        ROUTES.FORMS.LOCATION.ANY,
        ROUTES.FORMS.ENTITY.ANY,
        ROUTES.FORMS.PROBATION_OVERVIEW.ANY,
        ROUTES.FORMS.ONBOARDING_TIMELINE.ANY,
        ROUTES.FORMS.DOCUMENT_REQUEST.ANY,
        ROUTES.FORMS.DOCUMENT.ANY,
        ROUTES.FORMS.DOCUMENT_CATEGORIES.ANY,
        ROUTES.FORMS.DOCUMENT_TEMPLATE.ANY,
        ROUTES.FORMS.DOCUMENT_RULE.ANY,
        ROUTES.FORMS.ENGAGEMENT_QUESTION.ANY,
        ROUTES.FORMS.DYNAMIC_GROUPS.ANY,
        ROUTES.FORMS.CUSTOM_FIELDS.ANY,
        ROUTES.FORMS.NOTIFICATION_TEMPLATE.ANY,
        ROUTES.FORMS.BENEFITS.ANY,
        ROUTES.FORMS.EMPLOYEE_BENEFIT.ANY,
        ROUTES.FORMS.EMPLOYEE_BENEFIT_DEPENDANTS.ANY,
        ROUTES.FORMS.BENCHMARK.ANY,
        ROUTES.FORMS.TIME_OFF_BALANCE.ANY,
        ROUTES.FORMS.TIME_OFF_POLICY.ANY,
        ROUTES.FORMS.TIME_OFF_REGIMES.ANY,
        ROUTES.FORMS.EMPLOYEE_TIME_OFF_ABSENCE.ANY,
        ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.ANY,
        ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_UNASSIGNMENT.ANY,
        ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.ANY,
        ROUTES.FORMS.OFFBOARDING.ANY,
        ROUTES.FORMS.KEY_PERSON.ANY,
        ROUTES.FORMS.SCHEDULE_SHIFT.ANY,
        ROUTES.FORMS.SCHEDULING_POLICY.ANY,
        ROUTES.FORMS.SCHEDULING_POLICY_SHIFT.ANY,
        ROUTES.FORMS.ACCESS_REQUESTS.ANY,
        ROUTES.FORMS.DEFAULT_WORKING_HOURS.ANY,
        ROUTES.FORMS.MEETINGS_WITH.ANY,
      ],
      component: () => (
        <MainTableContainer sectionName={peopleSectionName} tabs={people.tabs} />
      ),
      canView: people.allTabsPermissions,
    },
    {
      name: recruitmentSectionName,
      icon: 'AddContact',
      url: ROUTES.RECRUITMENT.JOBS.REQUISITIONS,
      path: [ROUTES.RECRUITMENT.ANY],
      selectedPaths: [
        ROUTES.RECRUITMENT.ANY,
        ROUTES.FORMS.REQUISITION.ANY,
        ROUTES.FORMS.CANDIDATE.ANY,
        ROUTES.FORMS.INTERVIEW_FEEDBACK.ANY,
        ROUTES.FORMS.JOB_POSTING.ANY,
        ROUTES.FORMS.JOB_POSTING_AUTOMATION.ANY,
        ROUTES.FORMS.RECRUITMENT_GROUPS.ANY,
        ROUTES.FORMS.HIRING_STAGES.ANY,
        ROUTES.FORMS.HIRING_MANAGEMENT.ANY,
        ROUTES.FORMS.HIRING_PANEL.ANY,
        ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.ANY,
        ROUTES.FORMS.EMAIL_TEMPLATE.ANY,
        ROUTES.FORMS.OFFER_PLACEHOLDER.ANY,
        ROUTES.FORMS.OFFER_TEMPLATE.ANY,
      ],
      component: () => (
        <MainTableContainer
          sectionName={recruitmentSectionName}
          tabs={recruitment.tabs}
        />
      ),
      canView: recruitment.allTabsPermissions,
    },
    {
      name: performanceSectionName,
      icon: 'Wealth',
      url: ROUTES.PERFORMANCE.KPI.GOALS,
      path: [
        ROUTES.PERFORMANCE.ANALYTICS.ANY,
        ROUTES.PERFORMANCE.KPI.ANY,
        ROUTES.PERFORMANCE.ENGAGEMENT.ANY,
        ROUTES.PERFORMANCE.GOALS.ANY,
        ROUTES.PERFORMANCE.REVIEWS.ANY,
      ],
      selectedPaths: [
        ROUTES.PERFORMANCE.ANALYTICS.ANY,
        ROUTES.PERFORMANCE.ENGAGEMENT.ANY,
        ROUTES.PERFORMANCE.KPI.ANY,
        ROUTES.PERFORMANCE.GOALS.ANY,
        ROUTES.PERFORMANCE.REVIEWS.ANY,
        ROUTES.PERFORMANCE.REVIEWS_CALIBRATION,
      ],
      component: () => (
        <MainPage sectionName={performanceSectionName} tabs={performanceSubtabs} />
      ),
      canView: true,
    },
    {
      name: organisationSectionName,
      icon: 'Bank',
      url: ROUTES.ORGANISATION.TEAMS.DEPARTMENTS,
      path: [ROUTES.ORGANISATION.TEAMS.ANY, ROUTES.ORGANISATION.ROLES.ANY],
      selectedPaths: [
        ROUTES.ORGANISATION.ANY,
        ROUTES.FORMS.DEPARTMENT.ANY,
        ROUTES.FORMS.TEAM.ANY,
        ROUTES.FORMS.COMPANY.ANY,
        ROUTES.FUNCTION.ANY,
        ROUTES.FORMS.FUNCTION.ANY,
        ROUTES.FORMS.ROLE.ANY,
        ROUTES.FORMS.SPECIALISATIONS.ANY,
        ROUTES.FORMS.VIEW_CUSTOM_BUDGET_POOL,
        ROUTES.FORMS.EDIT_COMPANY_BUDGET,
        ROUTES.FORMS.EDIT_DEPARTMENT_BUDGET,
        ROUTES.FORMS.IMPORT_DATA.DEPARTMENT_BUDGET.ANY,
        ROUTES.FORMS.IMPORT_DATA.BONUSES.ANY,
        ROUTES.FORMS.TEAM_ACCESS_REQUESTS.ANY,
      ],
      component: () => (
        <MainTableContainer
          sectionName={organisationSectionName}
          tabs={organisation.tabs}
        />
      ),
      canView: organisation.allTabsPermissions,
    },
    {
      name: 'Features',
      icon: 'Apps',
      url: ROUTES.FEATURES.APPS,
      path: [ROUTES.FEATURES.ANY],
      selectedPaths: [
        ROUTES.FEATURES.ANY,
        ROUTES.ADMIN.ANY,
        ROUTES.PERFORMANCE.ANY,
        ROUTES.FORMS.KPI.ANY,
        ROUTES.FORMS.KPI_RULE.ANY,
        ROUTES.FORMS.KPI_TARGET.ANY,
        ROUTES.FORMS.KPI_TEMPLATE.ANY,
        ROUTES.FORMS.KPI_TEMPLATES.ANY,
        ROUTES.FORMS.KPI_WEIGHTS.ANY,
        ROUTES.FORMS.ASSIGN_KPIS.ANY,
        ROUTES.FORMS.APPROVE_KPIS.ANY,
        ROUTES.FORMS.PROMOTION_NOMINATION.ANY,
        ROUTES.FORMS.REVIEW_CYCLES.ANY,
        ROUTES.FORMS.SKILL.ANY,
        ROUTES.APPS.ANY,
      ],
      component: HubLandingPage,
      canView: true,
    },
  ]
}
