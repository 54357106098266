import React, { useEffect } from 'react'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { getSelectors } from '@src/api/selectors'

type LapeSkillsFieldProps = {
  isDisabled?: boolean
}

export const LapeSkillsField = ({ isDisabled }: LapeSkillsFieldProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const openNewTab = useOpenNewTab()

  const onFocus = async () => {
    // we create a skill in a new tab, close it there, user gets back to this page, we listen a focus event and prefill with the newly created skill
    const createdSkill = workspaceLocalStorage.getItem(LOCAL_STORAGE.SKILL_CREATED)

    if (createdSkill) {
      const skillsRes = await getSelectors(selectorKeys.approved_functional_skills)
      const skills = skillsRes.data.options
      const skill = skills?.find(item => item.id === +createdSkill)
      if (skill) {
        values.skills = [...values.skills, skill]
      }
      workspaceLocalStorage.removeItem(LOCAL_STORAGE.SKILL_CREATED)
    }
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)

    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [])

  return (
    <LapeNewMultiSelect<OptionInterface>
      showCreateNewButton
      disabled={isDisabled}
      name="skills"
      placeholder="Skills"
      selector={selectorKeys.approved_functional_skills}
      useQuery
      useQueryOptions={{
        refetchOnWindowFocus: true,
      }}
      onChange={options => {
        if (options) {
          if (options.find(({ value }) => value.id === createNewKey)) {
            openNewTab(
              pathToUrl(ROUTES.FORMS.SKILL.GENERAL, undefined, {
                ref: 'fill-skills-after-submit',
              }),
            )
          } else {
            values.skills = options.map(({ value }) => value)
          }
        }
      }}
    />
  )
}
