import React, { useState } from 'react'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import {
  Action,
  Box,
  Token,
  createChain,
  Group,
  HStack,
  InputGroup,
  MoreBar,
  Subheader,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import {
  CustomFieldInterface,
  OfferCreationInterface,
  SigningStatuses,
} from '@src/interfaces/offerCreation'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { formatMoney } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useParams } from 'react-router-dom'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import {
  changelogFieldsOfferRequests,
  changelogOfferRequests,
  getOfferComments,
  getPendingOfferChangesRequest,
  useGetOfferHTML,
  useGetRecommendedJobTitle,
  useOfferCreationRequestApprovals,
} from '@src/api/offerCreation'
import SettingsButtons, {
  ApproveButton,
  RejectButton,
  EditButton,
} from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import { Statuses } from '@src/interfaces'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import CommentsSection from '@src/features/Comments/CommentsSection'
import SendOfferButton from '@src/pages/Forms/OfferCreation/SendOfferButton'
import { EntityPermissions } from '@src/store/auth/types'
import { usePreviewPendingChanges } from '@components/FormPreview/changelog'
import PreviewDocumentSidebar from '@src/features/PreviewDocumentSidebar/PreviewDocumentSidebar'
import { Document, ExclamationTriangle, QuestionOutline } from '@revolut/icons'
import { saveFile } from '@src/utils'
import ReapprovalBanner from '@src/features/ApprovalFlow/ReapprovalBanner'
import { removeUnderscore } from '@src/utils/string'
import upperFirst from 'lodash/upperFirst'
import Tooltip from '@components/Tooltip/Tooltip'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import OfferChecklist from '@src/pages/Forms/OfferCreation/OfferChecklist'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import CancelOfferButton from '@src/pages/Forms/OfferCreation/CancelOfferButton'
import { InternalUIKitLink } from '@components/InternalLink/InternalLink'
import OfferStatus from '@src/pages/Forms/OfferCreation/OfferStatus'
import StyledHTMLLetter from '@src/components/StyledHTMLLetter'
import { useGetOfferSettings } from '@src/api/settings'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { parseLegacyApprovalSteps } from '@src/utils/approvalFlow'
import ArchivingCandidateSidebar from '@components/ArchivingCandidateSidebar/ArchivingCandidateSidebar'
import { useGetCandidate } from '@src/api/recruitment/interviews'

export const getSigningStatusText = (
  status: SigningStatuses,
  signingEnabled: boolean,
) => {
  const statuses = ['offer_not_sent', 'offer_signed']
  if (!signingEnabled && statuses.includes(status)) {
    return null
  }
  if (status === 'offer_cancelled_recruiter') {
    return 'Offer cancelled by recruiter'
  }
  if (status.includes('offer_declined')) {
    return `Offer declined by ${
      status === 'offer_declined_recruiter' ? 'recruiter' : 'candidate'
    }`
  }
  return upperFirst(removeUnderscore(status)) ?? null
}

type Props = {
  onRefresh: () => void
}

const Preview = ({ onRefresh }: Props) => {
  const { values, initialValues } = useLapeContext<OfferCreationInterface>()
  const [openPreviewSidebar, setOpenPreviewSidebar] = useState(false)
  const [showPendingChanges, setShowPendingChanges] = useState(true)
  const [openArchiveAction, setOpenArchiveAction] = useState(false)

  const { candidateId, id } = useParams<{ candidateId: string; id?: string }>()
  const { data: candidate } = useGetCandidate(candidateId)

  const { data: recommendedJobTitle, isLoading: loadingRecommendedJobTitle } =
    useGetRecommendedJobTitle(
      values.seniority?.id === undefined ? null : +values.seniority.id,
      values.specialisation?.id === undefined ? null : +values.specialisation.id,
    )

  const { data: offerHTML } = useGetOfferHTML(values.id)
  const { data: offerSettings, isLoading: isLoadingOfferSettings } = useGetOfferSettings()

  const enableApprovals = offerSettings?.enable_approvals
  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useOfferCreationRequestApprovals(enableApprovals ? values.id : undefined)
  const [isApproving, setIsApproving] = useState<boolean>(false)

  const offersEnabled = offerSettings?.enable_offer_templates

  const commentsApi = getOfferComments(values.id)
  const canChange = values.field_options?.actions?.includes(EntityPermissions.Change)

  const candidateField = (
    <FormPreview.Item<OfferCreationInterface>
      title="Candidate"
      insert={data => (
        <InternalUIKitLink
          // @ts-expect-error object works fine here, but UI kit expects string
          to={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
            id: data.candidate?.id,
          })}
          target="_blank"
        >
          {createChain(' ')(
            data.candidate_first_name,
            data.candidate_middle_name,
            data.candidate_last_name,
          )}
        </InternalUIKitLink>
      )}
    />
  )
  const positionField = (
    <FormPreview.Item<OfferCreationInterface>
      title="Specialisation"
      field="specialisation.name"
      to={data =>
        pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
          id: data?.specialisation?.id,
        })
      }
    />
  )
  const requisitionField = (
    <FormPreview.Item<OfferCreationInterface>
      title="Requisition"
      to={data =>
        pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {
          id: data?.requisition?.id,
        })
      }
      insert={data => `${data.requisition?.requisition_title} #${data.requisition?.id}`}
    />
  )
  const compensationField = (
    <FormPreview.Item<OfferCreationInterface>
      title="Compensation"
      field="compensation_amount"
      insert={data => {
        return createChain(' + ')(
          `${formatMoney(data.compensation_amount, data.currency?.iso_code)}`,
          data.is_equity_offered
            ? `${formatMoney(
                data.equity_amount,
                data.equity_currency?.iso_code,
              )} ${data.equity_type?.name?.toLowerCase()}`
            : undefined,
        )
      }}
    />
  )

  const teamField = (
    <FormPreview.Item<OfferCreationInterface>
      title="Team"
      field="team.name"
      to={data => pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: data?.team?.id })}
    />
  )

  const LMField = (
    <FormPreview.Item title="Line manager" field="line_manager" type="employee" />
  )
  const relocationField = (
    <FormPreview.Item
      title="Relocation required?"
      type="boolean"
      field="is_relocation_required"
    />
  )
  const offerTemplateField = (
    <FormPreview.Item<OfferCreationInterface>
      title="Offer template"
      field="offer_template.name"
      to={data =>
        pathToUrl(ROUTES.FORMS.OFFER_TEMPLATE.GENERAL, {
          id: data?.offer_template?.id,
        })
      }
    />
  )
  const jobTitleField = (
    <FormPreview.Item<OfferCreationInterface>
      title="Job title"
      field="job_title"
      insert={data => (
        <HStack gap="s-4" align="center">
          {data.job_title}{' '}
          {recommendedJobTitle !== data.job_title && (
            <Tooltip
              placement="top"
              text={`This title was edited from the recommended title "${recommendedJobTitle}"`}
            >
              <ExclamationTriangle
                color={Token.color.red}
                size={16}
                data-testid="job-title-warning"
              />
            </Tooltip>
          )}
        </HStack>
      )}
      loading={loadingRecommendedJobTitle}
    />
  )

  const customFieldBuilder = (field: CustomFieldInterface, idx: number) => {
    const commonProps = {
      key: field.id,
      title: field.label,
    }

    switch (field.field_type?.id) {
      case 'currency':
      case 'dropdown':
        return (
          <FormPreview.Item {...commonProps} field={`custom_fields[${idx}].value.name`} />
        )

      case 'userpicker':
        return (
          <FormPreview.Item
            {...commonProps}
            field={`custom_fields[${idx}].value`}
            type="employee"
          />
        )

      case 'number':
      case 'text':
        return <FormPreview.Item {...commonProps} field={`custom_fields[${idx}].value`} />

      case 'date':
        return (
          <FormPreview.Item
            {...commonProps}
            field={`custom_fields[${idx}].value`}
            type="date"
          />
        )

      default:
        return null
    }
  }

  const changelogApiOffer = {
    form: changelogOfferRequests(values.id),
    field: changelogFieldsOfferRequests(values.id),
  }

  const pendingChangelog = usePreviewPendingChanges<OfferCreationInterface>({
    api: changelogApiOffer,
    data: values,
    showPendingChanges,
    disabled: values.status !== Statuses.pending,
    pendingChangesRequest: getPendingOfferChangesRequest,
  })

  const showCancelButton = initialValues.signing_status
    ? ![
        'offer_declined_recruiter',
        'offer_declined_candidate',
        'offer_cancelled_recruiter',
      ].includes(initialValues.signing_status)
    : true

  return (
    <>
      <ArchivingCandidateSidebar
        candidate={candidate}
        open={openArchiveAction}
        onClose={() => setOpenArchiveAction(false)}
        onAfterArchive={() => setOpenArchiveAction(false)}
        showInvalidPlaceholdersWarning
      />
      {openPreviewSidebar && (
        <PreviewDocumentSidebar
          file={values.signed_offer_file!}
          onClose={() => setOpenPreviewSidebar(false)}
        />
      )}
      <PageBody>
        {canChange && offersEnabled && (
          <SettingsButtons mb="s-32">
            {initialValues.status === Statuses.pending && (
              <>
                <ApproveButton
                  isVisible={enableApprovals}
                  onBeforeSubmit={() => setIsApproving(true)}
                  onAfterSubmit={() => {
                    setIsApproving(false)
                    refetchApproval()
                  }}
                  onSubmitFailed={handleError}
                  statusFieldName="status"
                />
                <RejectButton
                  isVisible={enableApprovals}
                  onBeforeSubmit={() => setIsApproving(true)}
                  onAfterSubmit={() => {
                    setIsApproving(false)
                    refetchApproval()
                  }}
                  onSubmitFailed={handleError}
                  statusFieldName="status"
                  notification={{
                    updateMsg: 'Offer successfully rejected.',
                  }}
                  dialog={{
                    title: 'Reject offer letter?',
                    placeholder: 'Please provide reasons for rejection',
                    fieldName: 'rejection_comment',
                  }}
                />
              </>
            )}
            <EditButton
              route={pathToUrl(ROUTES.FORMS.OFFER_CREATION.GENERAL, { candidateId, id })}
            />
            {initialValues.status === Statuses.approved && (
              <SendOfferButton status={values.signing_status} onRefresh={onRefresh} />
            )}
            {showCancelButton && (
              <CancelOfferButton
                id={values.id}
                onArchive={() => setOpenArchiveAction(true)}
                signingStatus={values?.signing_status}
                onRefresh={onRefresh}
              />
            )}
            {values.signed_offer_file && (
              <MoreBar.Action
                useIcon={Document}
                onClick={() => setOpenPreviewSidebar(true)}
              >
                Preview signed offer
              </MoreBar.Action>
            )}
          </SettingsButtons>
        )}

        {offersEnabled && (
          <>
            {recommendedJobTitle !== values.job_title &&
              values.approval_step === 'function_owner' && (
                <ActionWidget
                  avatarColor={Token.color.blue}
                  title="Job title deviates from the recommended one"
                  text={
                    <>
                      <strong>Current job title:</strong> {values.job_title} <br />{' '}
                      <strong>Recommended job title:</strong> {recommendedJobTitle}
                    </>
                  }
                  mb="s-16"
                  data-testid="job-title-warning-widget"
                />
              )}

            {approvalSteps === undefined || approvalSteps?.length ? (
              <Box mb="s-16">
                <InputGroup>
                  {values.is_reapproval && initialValues.status !== Statuses.approved && (
                    <ReapprovalBanner
                      dataType="Offer"
                      actions={
                        <MoreBar>
                          <SwitchButton
                            onClick={() => setShowPendingChanges(!showPendingChanges)}
                            checked={showPendingChanges}
                          >
                            Show pending changes
                          </SwitchButton>
                        </MoreBar>
                      }
                    />
                  )}
                  {values.id && enableApprovals && (
                    <ApprovalFlow
                      isLoading={isApprovalLoading || isApproving}
                      steps={
                        approvalSteps ? parseLegacyApprovalSteps(approvalSteps) : null
                      }
                    />
                  )}
                </InputGroup>
              </Box>
            ) : null}

            {!isLoadingOfferSettings && !!offerSettings?.enable_offer_signing && (
              <OfferStatus
                cancelledDate={values?.cancelled_date}
                createdDate={values?.sent_date}
                declinedDate={values?.declined_date}
                expiredDate={values?.expired_date}
                expirationDate={values?.expected_expiration_date}
                openedDate={values?.opened_by_candidate_date}
                sentDate={values?.sent_from_docusign_date}
                signedDate={values?.signed_date}
                signingStatus={values?.signing_status}
              />
            )}
          </>
        )}

        <VStack gap="s-16">
          <HideIfCommercial>
            <Box>
              <Subheader variant="nested">
                <Subheader.Title>Checklist</Subheader.Title>
              </Subheader>
              <OfferChecklist id={values.id} />
            </Box>
          </HideIfCommercial>

          <FormPreview<OfferCreationInterface>
            data={values}
            title="Offer summary"
            changelog={pendingChangelog}
          >
            <Group>
              <FormPreview.Item<OfferCreationInterface>
                title="Signing status"
                insert={data =>
                  data.signing_status
                    ? getSigningStatusText(
                        data.signing_status,
                        !!offerSettings?.enable_offer_signing,
                      )
                    : '-'
                }
              />
              {candidateField}
              {jobTitleField}
              {positionField}
              {compensationField}
              {requisitionField}
              {teamField}
              {LMField}
              {relocationField}
              {offerTemplateField}
            </Group>
          </FormPreview>
          <FormPreview<OfferCreationInterface>
            data={values}
            title="Offer details"
            changelog={pendingChangelog}
          >
            <Group>
              <FormPreview.Header title="Candidate details" />
              <FormPreview.Item
                title="Candidate first name"
                field="candidate_first_name"
              />
              <FormPreview.Item
                title="Candidate middle name"
                field="candidate_middle_name"
              />
              <FormPreview.Item title="Candidate last name" field="candidate_last_name" />
              <FormPreview.Item
                title="Current candidate location"
                field="candidate_country.name"
              />
              <FormPreview.Item title="Work location" field="location.name" />
              <FormPreview.Divider />
              <FormPreview.Header title="Position details" />
              <FormPreview.Item title="Employment status" field="contract_type.name" />
              {requisitionField}
              {positionField}
              <FormPreview.Item title="Job title" field="job_title" />
              <FormPreview.Item title="Seniority" field="seniority.name" />
              <FormPreview.Divider />
              <FormPreview.Header title="Team" />
              {teamField}
              {LMField}
              <FormPreview.Item title="Legal entity" field="entity.name" />
              <FormPreview.Divider />
              <FormPreview.Header title="Relocation" />
              {relocationField}
              {values.is_relocation_required && (
                <>
                  <FormPreview.Item
                    title="Relocation/Visa Approval Received"
                    field="relocation_visa_approval_received"
                    type="boolean"
                  />
                  <FormPreview.Item<OfferCreationInterface>
                    title="Relocation/Visa Approval JIRA Ticket Link"
                    field="relocation_visa_approval_jira_ticket_url"
                    href={data => data.relocation_visa_approval_jira_ticket_url}
                  />
                </>
              )}
              <FormPreview.Divider />
              <FormPreview.Header title="Compensation" />
              <FormPreview.Item<OfferCreationInterface>
                title="Offer amount"
                insert={data =>
                  formatMoney(data.compensation_amount, data.currency?.iso_code)
                }
                field="compensation_amount"
              />
              <FormPreview.Item<OfferCreationInterface>
                title="Currency"
                field="currency.name"
              />
              <FormPreview.Item<OfferCreationInterface>
                title="Period"
                field="compensation_period.name"
              />
              <FormPreview.Item<OfferCreationInterface>
                title="Payment frequency"
                field="compensation_frequency.name"
              />
              <FormPreview.Item<OfferCreationInterface>
                title="Equity offered"
                insert={data =>
                  formatMoney(data.equity_amount, data.equity_currency?.iso_code)
                }
                field="equity_amount"
              />
              <FormPreview.Item<OfferCreationInterface>
                title="Type of equity"
                field="equity_type.name"
              />
              <FormPreview.Divider />
              <FormPreview.Header title="Additional" />
              <FormPreview.Item
                title="Anticipated start date"
                field="anticipated_start_date"
                type="date"
              />
              {values.custom_fields?.map((item, idx) => customFieldBuilder(item, idx))}
            </Group>
          </FormPreview>
          {values.notes && (
            <Box>
              <Subheader variant="nested">
                <Subheader.Title>Note from offer creator</Subheader.Title>
              </Subheader>
              <Widget p="s-16">
                <UserWithAvatar {...values.owner} size={40} mb="s-16" />
                <Text whiteSpace="pre-line">{values.notes}</Text>
              </Widget>
            </Box>
          )}
          {offersEnabled && offerHTML && (
            <Box data-testid="offer-letter">
              <Subheader variant="nested">
                <Subheader.Title>
                  <HStack gap="s-4">
                    Offer letter text preview
                    <Tooltip
                      placement="top"
                      text="This is the text preview. To view the actual offer letter, please click Download (docx) button"
                    >
                      <QuestionOutline size={16} />
                    </Tooltip>
                  </HStack>
                </Subheader.Title>
                {values.offer_file && (
                  <Subheader.Side>
                    <Action
                      onClick={() => {
                        saveFile(values.offer_file!.url!, values.offer_file!.saved_name)
                      }}
                    >
                      Download (docx)
                    </Action>
                  </Subheader.Side>
                )}
              </Subheader>
              <StyledHTMLLetter content={offerHTML.offer_html} />
            </Box>
          )}
          <CommentsSection api={commentsApi} maxShown={6} />
        </VStack>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup<OfferCreationInterface>
          successText="Offer saved successfully"
          useValidator
          hideWhenNoChanges
        />
      </PageActions>
    </>
  )
}

export default Preview
