import React, { ReactNode } from 'react'
import { Popup, Button, ButtonVariant, Header, Text } from '@revolut/ui-kit'

export interface ConfirmationDialogInterface {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  onReject?: () => void
  onExited?: () => void
  loading?: boolean
  label?: string
  body?: ReactNode
  yesMessage?: string
  noMessage?: string
  submitDisabled?: boolean
  showCloseButton?: boolean
  showHeader?: boolean
  yesBtnVariant?: ButtonVariant
  noBtnVariant?: ButtonVariant
  variant?: 'normal' | 'compact'
  bodyVariant?: 'normal' | 'compact'
}

function ConfirmationDialog({
  open,
  onClose,
  onConfirm,
  onReject,
  onExited,
  loading,
  yesMessage = 'Yes',
  noBtnVariant = 'secondary',
  yesBtnVariant,
  noMessage = 'No',
  label = 'Confirmation',
  body = 'Are you sure?',
  submitDisabled,
  showCloseButton,
  showHeader = true,
}: ConfirmationDialogInterface) {
  return (
    <Popup open={open} onClose={onClose} variant="bottom-sheet" onExited={onExited}>
      {showHeader ? (
        <Header variant="bottom-sheet">
          {showCloseButton && <Header.CloseButton aria-label="Close" />}
          <Header.Title>{label}</Header.Title>
        </Header>
      ) : null}
      {body && (
        <Text use="p" variant="caption" color="grey-tone-50">
          {body}
        </Text>
      )}
      <Popup.Actions horizontal>
        {onReject && (
          <Button data-testid="no" variant={noBtnVariant} onClick={onReject}>
            {noMessage}
          </Button>
        )}
        <Button
          pending={loading}
          data-testid="yes"
          elevated
          variant={yesBtnVariant}
          disabled={submitDisabled}
          onClick={onConfirm}
        >
          {yesMessage}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

/** @deprecated use useConfirmationDialog */
export default ConfirmationDialog
