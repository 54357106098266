import { getGoalsTargets } from '@src/api/goals'
import { useTable } from '@src/components/Table/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { GoalCycleSelectType } from '@src/pages/Forms/GoalForm/Form/Preview/GoalPreviewPage'
import { useEffect } from 'react'

export const useGoalTargetsTable = (reviewCycle?: GoalCycleSelectType) => {
  const { values } = useLapeContext<GoalsInterface>()

  const table = useTable<GoalKpiDetails>(
    { getItems: getGoalsTargets },
    [
      {
        columnName: 'goal__id',
        filters: [{ id: values.id, name: values.name }],
        nonResettable: true,
      },
      {
        columnName: 'cycle__id',
        // using "cycle.id!" because type system doesn't support ID as undefined and it's a required field
        // we need undefined here to be able to select "all cycles" as filter value, not just clear the filter
        // refactoring type system seems to be time consuming
        filters: [{ id: reviewCycle?.id!, name: reviewCycle?.name! }],
      },
    ],
    undefined,
    { disableQuery: true },
  )

  useEffect(() => {
    values.kpis = table.data
  }, [table.data])

  return table
}
