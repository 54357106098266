import React, { useEffect, useRef } from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import SidebarFeedbacks from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarFeedbacks'
import { useGetAllFeedbackList } from '@src/api/anytimeFeedback'
import { ReviewSummaryDataInterface } from '@src/interfaces/performance'
import { FeedbackSection } from '@src/pages/EmployeeProfile/Layout/Performance/FeedbackSection'
import { Text, Token, VStack } from '@revolut/ui-kit'
import { tabsCategory } from '@components/AnytimeFeedback/AnytimeFeedbackSidebar'

export const SummaryFeedbackSection = ({
  employee,
  summary,
  scrollToNotes,
}: {
  employee: EmployeeInterface
  summary?: ReviewSummaryDataInterface
  scrollToNotes?: boolean
}) => {
  const { data: feedback, refetch: refetchFeedbackList } = useGetAllFeedbackList(
    employee.id,
  )
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollToNotes) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }, [scrollToNotes])

  return (
    <VStack space="s-16">
      <Text variant="h6" color={Token.color.greyTone50} ref={scrollRef}>
        Feedback
      </Text>
      <FeedbackSection
        data={employee}
        feedback={feedback}
        refetchFeedbackList={refetchFeedbackList}
        performanceContent={
          summary?.overall_feedbacks?.length ? (
            <SidebarFeedbacks
              feedbacks={summary.overall_feedbacks}
              ratings={summary.ratings}
              renderTitle={false}
            />
          ) : undefined
        }
        initialTabId={scrollToNotes ? tabsCategory.OneToOne : undefined}
      />
    </VStack>
  )
}
