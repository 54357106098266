import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  DeliverableOptions,
  PerformanceRating,
  ReviewCalculatedDeliverablesRatingsInterface,
  ReviewDataSectionInterface,
  SkillCardInterface,
  SkillSummary,
  SummaryCalculatedDeliverablesRatingsInterface,
  SummarySkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { selectorKeys } from '@src/constants/api'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { Token } from '@revolut/ui-kit'
import { RecommendationQuestionInterface } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserSummary'
import { KpiRowInterface } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/SummaryReviewTables'

const getCellBackground = (rating: PerformanceRating | DeliverableOptions | null) => {
  switch (rating) {
    case PerformanceRating.basic:
    case PerformanceRating.basic_plus:
    case PerformanceRating.performing:
      return Token.color.teal_5
    case PerformanceRating.poor:
    case PerformanceRating.poor_plus:
    case PerformanceRating.improvement_needed:
      return Token.color.orange_5
    case PerformanceRating.intermediate_minus:
    case PerformanceRating.intermediate:
    case PerformanceRating.intermediate_plus:
    case PerformanceRating.superpower:
      return Token.color.teal_30
    case PerformanceRating.advanced_minus:
    case PerformanceRating.advanced:
    case PerformanceRating.advanced_plus:
      return Token.color.teal_70
    case PerformanceRating.expert_minus:
    case PerformanceRating.expert:
      return Token.color.teal
    default:
      return Token.color.background
  }
}

export const performanceSummaryDeliverablesNameColumn: ColumnInterface<
  | ReviewDataSectionInterface
  | ReviewCalculatedDeliverablesRatingsInterface
  | SummaryCalculatedDeliverablesRatingsInterface
  | SkillCardInterface
  | SummarySkillCardInterface
  | SkillSummary
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: () => 'Contribution and impact',
}

export const performanceSummaryRatingColumn: ColumnInterface<
  | ReviewDataSectionInterface
  | SkillCardInterface
  | ValueBasedCardInterface
  | SkillSummary
  | SummarySkillCardInterface
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Latest rating',
  background: data => getCellBackground(data.rating || null),
}

export const performanceSummaryCalculatedRatingColumn: ColumnInterface<ReviewCalculatedDeliverablesRatingsInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Latest rating',
    background: data => {
      const rating = data.recommended_rating || data.rating
      return getCellBackground(rating)
    },
  }

export const performanceSummaryExpectedCalcRatingColumn: ColumnInterface<ReviewCalculatedDeliverablesRatingsInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Expected rating',
    insert: ({ data }) => {
      return PerformanceRatingTitle[data.rating_expectation || 'none']
    },
    background: data => getCellBackground(data.rating_expectation || null),
  }

export const performanceSummaryRecommendedRatingColumn: ColumnInterface<KpiRowInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Latest rating',
    background: data => getCellBackground(data.rating || null),
  }

export const performanceSummaryExpectedRatingColumn: ColumnInterface<
  | ReviewDataSectionInterface
  | KpiRowInterface
  | SkillCardInterface
  | SkillSummary
  | SummarySkillCardInterface
> = {
  type: CellTypes.insert,
  idPoint: 'rating_expectation',
  dataPoint: 'rating_expectation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Expected rating',
  insert: ({ data }) => PerformanceRatingTitle[data.rating_expectation || 'none'],
  background: data => getCellBackground(data.rating_expectation || null),
}

export const performanceSummarySkillsNameColumn: ColumnInterface<
  | SummarySkillCardInterface
  | SkillCardInterface
  | ValueBasedCardInterface
  | RecommendationQuestionInterface
> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const performanceSummaryBarRaiserReviewerColumn: ColumnInterface<RecommendationQuestionInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'LM',
  }
