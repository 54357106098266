import React, { ReactNode } from 'react'
import { Widget, Item, Avatar, ActionButton, DetailsCell } from '@revolut/ui-kit'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { FormattedMessage } from 'react-intl'
import { getScheduleString } from '@src/apps/People/Engagement/helpers'

const Details = ({ title, value }: { title: ReactNode; value: ReactNode }) => (
  <DetailsCell>
    <DetailsCell.Title>{title}</DetailsCell.Title>
    <DetailsCell.Content>{value}</DetailsCell.Content>
  </DetailsCell>
)

export const Timing = ({
  onEdit,
  ...surveyValues
}: EngagementSurveyInterface & { onEdit: VoidFunction }) => {
  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Time" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            <FormattedMessage id="engagement.timing.title" defaultMessage="Timing" />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="engagement.timing.description"
              defaultMessage="When will the survey run"
            />
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton onClick={onEdit}>
            <FormattedMessage id="common.edit" defaultMessage="Edit" />
          </ActionButton>
        </Item.Side>
      </Item>
      <Details
        title={
          <FormattedMessage
            id="engagement.timing.schedule.title"
            defaultMessage="Schedule"
          />
        }
        value={getScheduleString(surveyValues)}
      />
      {surveyValues.sent_every.id !== 'manual' && (
        <Details
          title={
            <FormattedMessage
              id="engagement.timing.duration.title"
              defaultMessage="Duration"
            />
          }
          value={
            <FormattedMessage
              id="engagement.timing.duration.value"
              values={{ count: surveyValues.response_deadline_days }}
              defaultMessage="{count, plural, one {# day} other {# days}}"
            />
          }
        />
      )}
      {surveyValues.sent_every.id !== 'manual' && (
        <Details
          title={
            <FormattedMessage
              id="engagement.timing.timezone.title"
              defaultMessage="Timezone"
            />
          }
          value={surveyValues.send_on_timezone?.name}
        />
      )}
    </Widget>
  )
}
