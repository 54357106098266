import React, { useContext, useMemo, useState } from 'react'

export const CardItemContext = React.createContext<{
  currentItem: string | null
  setCurrentItem: React.Dispatch<React.SetStateAction<string | null>>
} | null>(null)

export const useCardItemContext = () => {
  return useContext(CardItemContext)
}

export const ScorecardCardItemContextProvider: React.FC = ({ children }) => {
  const [currentItem, setCurrentItem] = useState<string | null>(null)

  const contextValue = useMemo(
    () => ({ currentItem, setCurrentItem }),
    [currentItem, setCurrentItem],
  )

  return (
    <CardItemContext.Provider value={contextValue}>{children}</CardItemContext.Provider>
  )
}
