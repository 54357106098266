import React from 'react'
import { Box } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { QuestionsTab } from './QuestionsTab'
import { CreateNewQuestionTab } from './CreateNewQuestionTab'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'

enum SidebarTabs {
  QuestionBank = 'Question bank',
  CreateNew = 'Create new',
}

const tabs = [SidebarTabs.QuestionBank, SidebarTabs.CreateNew]

type Props = {
  open: boolean
  onClose: () => void
  onAddQuestion: (
    q: EngagementQuestionInterface | EngagementQuestionInterface[],
    closeSidebar?: boolean,
  ) => void
  addedQuestionsIds: number[]
}
export const AddQuestionSidebar = ({
  open,
  onClose,
  onAddQuestion,
  addedQuestionsIds,
}: Props) => {
  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs,
    highlightSelected: false,
  })

  return (
    <SideBar variant="wide" isOpen={open} title="Add question" onClose={onClose}>
      <Box mb="s-24">{tabBar}</Box>
      {currentTab === SidebarTabs.QuestionBank && (
        <QuestionsTab
          onAddQuestion={onAddQuestion}
          addedQuestionsIds={addedQuestionsIds}
        />
      )}
      {currentTab === SidebarTabs.CreateNew && (
        <CreateNewQuestionTab onAddQuestion={onAddQuestion} />
      )}
    </SideBar>
  )
}
