import React, { useEffect, useState } from 'react'
import { Button, HStack, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { getBackUrl, navigateReplace } from '@src/actions/RouterActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Statuses } from '@src/interfaces'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { EngagementEditStep } from '@src/pages/Performance/Engagement/types'
import { activateEngagementSurvey } from '@src/api/engagement'
import { FormattedMessage } from 'react-intl'
import { completeOnboardingCheckpoint } from '@src/api/onboardingChecklistV2'
import { engagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

const nextStepMap: Record<EngagementEditStep, EngagementEditStep | 'finish'> = {
  basic: 'questions',
  questions: 'audience',
  audience: 'notifications',
  notifications: 'review',
  review: 'finish',
}

export const FormActions = ({ currentStep }: { currentStep: EngagementEditStep }) => {
  const [isPending, setIsPending] = useState(false)
  const isOnboarding = isOnboardingPath()
  const statusPopup = useStatusPopup()

  const { values, dirty } = useLapeContext<EngagementSurveyInterface>()

  const isNew = !values.id
  const isDraft = values.status?.id === Statuses.inactive
  const isFromTemplate = values.status?.id === Statuses.hidden_draft

  useEffect(() => {
    if (isNew) {
      if (!values.survey_questions) {
        values.survey_questions = []
      }
      if (!values.audiences) {
        values.audiences = []
      }
    }
  }, [])

  const handleLaunchSurvey = async () => {
    if (isNew) {
      return
    }
    setIsPending(true)
    try {
      await activateEngagementSurvey(values?.id)
      if (isFromTemplate && isOnboarding) {
        await completeOnboardingCheckpoint(engagementConfig.category)
      }
      statusPopup.show(
        <StatusPopup
          variant="success-result"
          onClose={() => {
            statusPopup.hide()
            navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
          }}
        >
          <StatusPopup.Title>Congratulations!</StatusPopup.Title>
          <StatusPopup.Description>You've set up your survey</StatusPopup.Description>
          <StatusPopup.Actions>
            <Button
              onClick={() => {
                statusPopup.hide()
                navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
              }}
            >
              <FormattedMessage
                id="onboardingV2.general.goToDashboard"
                defaultMessage="Go to Dashboard"
              />
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't complete the step</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }

  const handleSaveButtonClick = async ({
    variant,
    id,
  }: {
    variant: 'draft' | 'next'
    id?: string | number
  }) => {
    if (nextStepMap[currentStep] === 'finish') {
      handleLaunchSurvey()
    } else {
      navigateReplace(
        variant === 'draft'
          ? ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD
          : pathToUrl(
              isOnboarding
                ? ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.CUSTOMISE
                : ROUTES.FORMS.ENGAGEMENT.EDIT,
              {
                id,
                step: nextStepMap[currentStep],
              },
            ),
      )
    }
  }

  const getNextButton = () => {
    if (nextStepMap[currentStep] === 'finish') {
      return 'Finish'
    }

    if (isNew) {
      return 'Next'
    }

    if (dirty) {
      return 'Save changes'
    }

    return 'Next'
  }

  return (
    <HStack gap="s-16">
      {isFromTemplate && (
        <Button
          onClick={e => {
            e.preventDefault()
            navigateReplace(getBackUrl(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD).pathname)
          }}
          variant="secondary"
        >
          Cancel
        </Button>
      )}
      {(isDraft || isNew) && (
        <NewSaveButtonWithPopup
          hideWhenNoChanges={false}
          pending={isPending}
          useValidator
          noPopup
          variant="secondary"
          onAfterSubmit={results =>
            handleSaveButtonClick({ variant: 'draft', id: results.id })
          }
        >
          Save as draft
        </NewSaveButtonWithPopup>
      )}
      <NewSaveButtonWithPopup
        hideWhenNoChanges={false}
        pending={isPending}
        useValidator
        noPopup
        onAfterSubmit={results =>
          handleSaveButtonClick({ variant: 'next', id: results.id })
        }
      >
        {getNextButton()}
      </NewSaveButtonWithPopup>
    </HStack>
  )
}
