import React from 'react'
import { useSelector } from 'react-redux'

import { Box, HStack, MoreBar, Token } from '@revolut/ui-kit'
import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  EngagementSurveyInterface,
  EngagementSurveyStatsInterface,
} from '@src/interfaces/engagement'
import { engagementSurveysTableRequests } from '@src/api/engagement'
import {
  surveyNameColumn,
  surveyRecurrenceColumn,
  surveyAudienceColumn,
  surveyUpdatedByColumn,
  surveyUpdatedOnColumn,
  surveyStatusColumn,
  surveySentOnColumn,
} from '@src/constants/columns/engagementSurveys'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@components/Stat/Stat'
import { TableNames } from '@src/constants/table'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { TableWrapper } from '@components/Table/TableWrapper'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { Statuses } from '@src/interfaces'

const ROW: RowInterface<EngagementSurveyInterface> = {
  cells: [
    {
      ...surveyNameColumn,
      width: 300,
    },
    {
      ...surveyRecurrenceColumn,
      width: 250,
    },
    {
      ...surveyAudienceColumn,
      width: 200,
    },
    {
      ...surveySentOnColumn,
      width: 200,
    },
    {
      ...surveyUpdatedByColumn,
      width: 200,
    },
    {
      ...surveyUpdatedOnColumn,
      width: 200,
    },
    {
      ...surveyStatusColumn,
      width: 200,
    },
  ],
}

export const SurveysTable = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.EngagementManagerPermissions)

  const table = useTable<EngagementSurveyInterface, EngagementSurveyStatsInterface>(
    engagementSurveysTableRequests,
    [
      {
        columnName: 'status',
        filters: [
          { id: Statuses.active, name: Statuses.active },
          { id: Statuses.inactive, name: Statuses.inactive },
          { id: Statuses.terminated, name: Statuses.terminated },
          { id: Statuses.archived, name: Statuses.archived },
        ],
      },
    ],
  )

  return (
    <TableWrapper>
      <HStack space="s-32" mb="s-16">
        <Stat label="Total" val={table.stats?.total} color={Token.color.foreground} />
        <Stat label="Active" val={table.stats?.active} color={Token.color.green} />
        <Stat label="Inactive" val={table.stats?.inactive} color={Token.color.orange} />
        <Stat
          label="Archived"
          val={table.stats?.archived}
          color={Token.color.greyTone50}
        />
      </HStack>
      <Box mb="s-16">
        <MoreBar>
          {canAdd && (
            <MoreBar.Action
              useIcon="Plus"
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.ENGAGEMENT.EDIT, { step: 'basic' })}
            >
              Add new
            </MoreBar.Action>
          )}
        </MoreBar>
      </Box>
      <AdjustableTable<EngagementSurveyInterface, EngagementSurveyStatsInterface>
        name={TableNames.EngagementSurveys}
        useWindowScroll
        row={ROW}
        {...table}
        onRowClick={(row: { id: number }) => {
          navigateTo(pathToUrl(ROUTES.APPS.ENGAGEMENT.SURVEY.HISTORY, { id: row.id }))
        }}
        noDataMessage="All engagement surveys will appear here"
      />
    </TableWrapper>
  )
}
