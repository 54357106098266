import React, { useMemo } from 'react'
import { ROUTES } from '@src/constants/routes'
import { Box, Cell, VStack } from '@revolut/ui-kit'

import { DriversTable } from '@src/apps/People/Engagement/DriversTable/DriversTable'
import { QuestionsTable } from '@src/apps/People/Engagement/QuestionsTable/QuestionsTable'
import { SurveysTable } from '@src/apps/People/Engagement/SurveysTable/SurveysTable'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'

import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

const engagementTabs = [
  {
    title: 'Surveys',
    path: ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD,
    url: ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD,
    canView: [PermissionTypes.ViewEngagement],
    component: SurveysTable,
  },
  {
    title: 'Drivers',
    path: ROUTES.PERFORMANCE.ENGAGEMENT.DRIVERS,
    url: ROUTES.PERFORMANCE.ENGAGEMENT.DRIVERS,
    canView: [PermissionTypes.ViewEngagement],
    component: DriversTable,
  },
  {
    title: 'Questions',
    path: ROUTES.PERFORMANCE.ENGAGEMENT.QUESTIONS,
    url: ROUTES.PERFORMANCE.ENGAGEMENT.QUESTIONS,
    canView: [PermissionTypes.ViewEngagement],
    component: QuestionsTable,
  },
]

const EngagementBase = () => {
  // TODO create nice dashboard
  // https://revolut.atlassian.net/browse/REVC-6399

  const permissions = useSelector(selectPermissions)
  const tabs = useMemo(() => {
    return engagementTabs.filter(({ canView }) =>
      canView.some(permission => permissions.includes(permission)),
    )
  }, [])

  return (
    <VStack space="s-24">
      <Cell px={0} pb={0}>
        <VStack width="100%">
          <Box px="s-16" pb="s-8">
            <TabBarNavigation tabs={tabs} isSubtab />
          </Box>

          <Switch>
            {tabs.map(tab => (
              <Route exact path={tab.path} key={tab.title}>
                <tab.component />
              </Route>
            ))}
            <InternalRedirect to={tabs[0].url} />
          </Switch>
        </VStack>
      </Cell>
    </VStack>
  )
}

export const Engagement = () => {
  return (
    <OnboardingAppScreen category="engagement">
      <EngagementBase />
    </OnboardingAppScreen>
  )
}
