import React, { useEffect, useState } from 'react'
import pluralize from 'pluralize'

import {
  Avatar,
  Cell,
  chain,
  Token,
  Flex,
  Group,
  HStack,
  IconButton,
  Item,
  Portal,
  Text,
  VStack,
  ActionButton,
} from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  EngagementQuestionInterface,
  EngagementSurveyInterface,
} from '@src/interfaces/engagement'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { DraggableQuestions } from '@src/apps/People/Engagement/Survey/components/DraggableQuestions/DraggableQuestions'
import { sortSurveyQuestionsByOrder } from '@src/apps/People/Engagement/helpers'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { AddQuestionSidebar } from './components/AddQuestionSidebar'
import { AddAudienceSidebar } from './components/AddAudienceSidebar'
import ScheduleWidget from '@src/features/ScheduleWidget/ScheduleWidget'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { flatten } from 'lodash'

const DEFAULT_RESPONSE_DEADLINE = 2

export const SurveyForm = () => {
  const { values } = useLapeContext<EngagementSurveyInterface>()
  const [questionsSidebarOpen, setQuestionsSidebarOpen] = useState(false)
  const [addedQuestions, setAddedQuestions] = useState<EngagementQuestionInterface[]>([])
  const [audiencesSidebarOpen, setAudiencesSidebarOpen] = useState(false)
  const [responseDeadline, setResponseDeadline] = useState(
    values.response_deadline_days || DEFAULT_RESPONSE_DEADLINE,
  )

  useEffect(() => {
    if (values.id) {
      setAddedQuestions(sortSurveyQuestionsByOrder(values.survey_questions))
    }
  }, [])

  useEffect(() => {
    values.response_deadline_days = responseDeadline
  }, [responseDeadline])

  useEffect(() => {
    values.survey_questions = addedQuestions.map(question => ({
      question,
      order: addedQuestions.findIndex(q => q.id === question.id),
    }))
  }, [addedQuestions])

  const title = values.id ? 'Edit survey' : 'Add new survey'

  const handleDeleteAddedQuestion = (id: number) => {
    setAddedQuestions(addedQuestions.filter(q => q.id !== id))
  }
  const handleChangeQuestionsOrder = (id: number, overId: number | null) => {
    if (!overId) {
      return
    }
    const targetIdx = addedQuestions.findIndex(q => q.id === id)
    const overIdx = addedQuestions.findIndex(q => q.id === overId)
    const questionsWithoutDragged = addedQuestions.filter(q => q.id !== id)

    const changedOrderQuestions = [
      ...questionsWithoutDragged.slice(0, overIdx),
      addedQuestions[targetIdx],
      ...questionsWithoutDragged.slice(overIdx, addedQuestions.length - 1),
    ]
    setAddedQuestions(changedOrderQuestions)
  }

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={ROUTES.APPS.ENGAGEMENT.SURVEYS_TABLE} />
      <PageBody>
        <VStack space="s-24" mb="s-24">
          <Cell>
            <VStack width="100%" space="s-16">
              <VStack>
                <Text variant="tile">General</Text>
                <Text variant="caption" color={Token.color.greyTone50}>
                  Who is eligible to answer this survey?
                </Text>
              </VStack>
              <LapeNewInput
                label="Title"
                message="This will appear at the top of the engagement survey page when employees answer questions."
                name="title"
                required
              />
              <LapeHTMLEditor
                greyBg
                required
                name="instructions"
                placeholder="Instructions"
                message="This will appear before employees start answering questions."
                height={250}
              />
              <LapeRadioSelectInput
                required
                name="owner"
                label="Owner"
                selector={selectorKeys.employee}
              />
            </VStack>
          </Cell>
          <Cell>
            <ScheduleWidget
              width="100%"
              selector={selectorKeys.engagement_survey_recurrence}
            />
          </Cell>
          <Cell>
            <VStack width="100%" space="s-16">
              <Flex justifyContent="space-between" data-testid="add_audience_section">
                <VStack>
                  <Text variant="tile">Audience</Text>
                  <Text variant="caption" color={Token.color.greyTone50}>
                    Which employees should receive this survey?
                  </Text>
                </VStack>
                <ActionButton
                  useIcon="Plus"
                  onClick={() => setAudiencesSidebarOpen(true)}
                >
                  Add new
                </ActionButton>
              </Flex>
              <Group>
                {values.audiences?.map(audience => (
                  <Item key={audience.id} p="s-8">
                    <Item.Avatar>
                      <Avatar useIcon="People" />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title>{audience.name}</Item.Title>
                      <Item.Description>
                        {chain(
                          audience.audience_type.name,
                          `${pluralize('member', audience.headcount, true)}`,
                        )}
                      </Item.Description>
                    </Item.Content>
                    <Item.Side>
                      <IconButton
                        useIcon="Delete"
                        color={Token.color.greyTone10}
                        onClick={() => {
                          values.audiences = values.audiences.filter(
                            currentAudience => currentAudience.id !== audience.id,
                          )
                        }}
                      />
                    </Item.Side>
                  </Item>
                ))}
              </Group>
            </VStack>
          </Cell>
          <Cell>
            <VStack width="100%">
              <Flex justifyContent="space-between" data-testid="add_question_section">
                <VStack mb="s-16">
                  <Text variant="tile">Questions</Text>
                  <Text variant="caption" color={Token.color.greyTone50}>
                    Which questions should be included in the survey and in which order?
                  </Text>
                </VStack>
                <ActionButton
                  useIcon="Plus"
                  onClick={() => setQuestionsSidebarOpen(true)}
                >
                  Add new
                </ActionButton>
              </Flex>
              <DraggableQuestions
                addedQuestions={addedQuestions}
                onDeleteQuestion={id => handleDeleteAddedQuestion(id)}
                onChangeQuestionsOrder={handleChangeQuestionsOrder}
              />
            </VStack>
          </Cell>
          <Cell>
            <Flex width="100%" justifyContent="space-between" alignItems="center">
              <VStack>
                <Text variant="tile">Response deadline</Text>
                <Text variant="caption" color={Token.color.greyTone50}>
                  Maximum number of days to answer all the questions in the survey
                </Text>
              </VStack>
              <HStack space="s-16" align="center">
                <ActionButton
                  useIcon="Minus"
                  disabled={!responseDeadline}
                  onClick={() => {
                    if (responseDeadline > 0) {
                      setResponseDeadline(responseDeadline - 1)
                    }
                  }}
                />
                <Text color={Token.color.blue} fontWeight={500}>
                  {pluralize('day', responseDeadline, true)}
                </Text>
                <ActionButton
                  useIcon="Plus"
                  onClick={() => {
                    setResponseDeadline(responseDeadline + 1)
                  }}
                />
              </HStack>
            </Flex>
          </Cell>
          <Cell>
            <VStack width="100%" space="s-16">
              <VStack>
                <Text variant="tile">Notifications</Text>
              </VStack>
              <LapeNewInput required label="Name" name="display_name" />
              <LapeNewTextArea rows={3} label="Description" name="description" />
              <LapeRadioSelectInput
                name="campaign"
                label="Select campaign"
                selector={selectorKeys.engagement_survey_campaigns}
              />
              <LapeNewInput
                required
                label="Completion time in minutes"
                type="number"
                name="completion_minutes"
              />
            </VStack>
          </Cell>
          <LapeNewSwitch
            name="slack_notification_enabled"
            itemTypeProps={{
              title: 'Enable Slack notifications',
            }}
          />
        </VStack>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText={
            values.id ? 'Survey successfully updated' : 'Survey successfully added'
          }
          onAfterSubmit={res => {
            navigateReplace(
              pathToUrl(ROUTES.APPS.ENGAGEMENT.SURVEY.PREVIEW, { id: res.id }),
            )
          }}
        >
          Save
        </NewSaveButtonWithPopup>
      </PageActions>

      <Portal>
        <AddQuestionSidebar
          open={questionsSidebarOpen}
          onClose={() => setQuestionsSidebarOpen(false)}
          onAddQuestion={(newQuestion, closeSidebar) => {
            setAddedQuestions([...addedQuestions, ...flatten([newQuestion])])

            if (closeSidebar) {
              setQuestionsSidebarOpen(false)
            }
          }}
          addedQuestionsIds={addedQuestions.map(({ id }) => id)}
        />
        <AddAudienceSidebar
          open={audiencesSidebarOpen}
          onClose={() => setAudiencesSidebarOpen(false)}
          audiences={values.audiences}
          onAddAudiences={addedAudiences => {
            values.audiences = [...(values.audiences || []), ...addedAudiences]
          }}
        />
      </Portal>
    </PageWrapper>
  )
}
