import { Cell, Image, Item, VStack } from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const Notifications = () => {
  return (
    <>
      <Cell>
        <VStack gap="s-16" width="100%">
          <Item p="0">
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="engagement.form.notification.details.title"
                  defaultMessage="Notification details"
                />
              </Item.Title>
              <Item.Description>
                <FormattedMessage
                  id="engagement.form.notification.details.description"
                  defaultMessage="Create notification which will be sent on the run of the survey to all participants"
                />
              </Item.Description>
            </Item.Content>
          </Item>
          <LapeNewInput required label="Name" name="display_name" />
          <LapeNewTextArea rows={3} label="Description" name="description" />
          <LapeRadioSelectInput
            name="campaign"
            label="Select campaign"
            selector={selectorKeys.engagement_survey_campaigns}
          />
          <LapeNewInput
            required
            label="Completion time in minutes"
            type="number"
            name="completion_minutes"
          />
        </VStack>
      </Cell>
      <Cell>
        <VStack gap="s-16" width="100%">
          <Item p="0">
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="engagement.form.notification.channels.title"
                  defaultMessage="Channels"
                />
              </Item.Title>
              <Item.Description>
                <FormattedMessage
                  id="engagement.form.notification.channels.description"
                  defaultMessage="Define what channels you want to use"
                />
              </Item.Description>
            </Item.Content>
          </Item>
          <LapeNewSwitch
            name="slack_notification_enabled"
            itemTypeProps={{
              avatar: (
                <Item.Avatar>
                  <Image src="https://assets.revolut.com/assets/apps/Slack.png" />
                </Item.Avatar>
              ),
              title: 'Slack',
              description: 'Enable Slack notifications',
            }}
          />
        </VStack>
      </Cell>
    </>
  )
}
